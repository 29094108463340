import { shapes } from '@greenville/framework';
import { makeStyles } from '@material-ui/core';
import { ChatComponent } from '@vega/aitutor';
import axios from 'axios';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import utils from '../../../common/utils';
import chapterList from '../data/chapterList.json';
import GetPiTokenModel from '../models/GetPiTokenModel';

const useStyles = makeStyles({
  heading: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    top: '13%',
    fontSize: '18px',
    fontWeight: '600'
  }
});

const ServiceNow = () => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(true);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [tokenExpireTime, setTokenExpireTime] = useState(null);
  const [tokenValue, setTokenValue] = useState('');

  const getPiTokenApiCall = async () => {
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_PI_TOKEN_URL}`, { headers });
    if (response && response.data && response.data.data) {
      const { token, expiresIn } = response.data.data;
      setUserToken(token);
      setTokenExpireTime(expiresIn);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setTokenValue(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tokenValue)) {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 500);
      getPiTokenApiCall();
    }
  }, [tokenValue]);
  /* eslint-disable */

  useEffect(() => {
    if (!tokenExpireTime) return;
    const timeLeft = tokenExpireTime - Date.now() - 59000;
    const timeOutId = setTimeout(() => {
      getPiTokenApiCall();
    }, timeLeft);
    return () => clearTimeout(timeOutId);
  }, [tokenExpireTime]);

  /* eslint-disable */
  const Users = {
    username: 'Vignesh Selvam',
    initials: 'VS',
    userId: 'd21cfc760a304fbea4dc40e4b54cceb9',
    token: userToken,
    isFirstTimeAITutorUser: false
  };

  const getAuthToken = (callback) => {
    const tokenConfig = {
      id: 'token',
      token: userToken
    };
    return new Promise((resolve) => {
      resolve(callback(tokenConfig.token));
    });
  };

  const getCurrentSection = () => {
    return {
      chapterData: {
        chapterId: 'cfc05d6e0-31ca-11ee-8eb0-d709f5427093',
        chapterTitle: 'Chapter 4: Carbon and the Molecular Diversity of Life',
        chapterNumber: 19
      }
    };
  };

  const handleChatbotOpened = (event) => {
    console.log('handleChatbotOpened callback', event);
  };

  return (
    <div className={classes.contentWrapper}>
      <div className={classes.heading}> Welcome to Peri </div>

      <ChatComponent
        isPageLoaded={isPageLoaded} // Required
        env="qainactive" // {env.ENVIRONMENT} // Required
        users={Users} // Required
        channelsVideoEsScore="1"
        theme="default" // Required
        masterTheme="plus" // Required
        locale="en-us" // Optional
        context={{
          bookTitle: 'Campbell Biology, 12e', // Required
          productModel: 'ETEXT_EPUB_BRONTE', // Required
          indexId: '0fc5534dbbeaad9b8dbdbdaa9ff4a74f', // Required
          pageId: 'f57c8f80-31ca-11ee-8109-7e1731ad9766', // Required
          printPageNumber: '5', // Optional
          bookID: 'BRNT-XUF8BS197', // Required
          isPpmTextExtracted: false, // Optional
          secondaryBookId: 'd9096892-1923-4685-ab27-b6c32dd9455d', // Required
          chapterList, // Required
          chapterId: 'cfc05d6e0-31ca-11ee-8eb0-d709f5427093', // Required
          chapterTitle: 'Chapter 4: Carbon and the Molecular Diversity of Life', // Required
          chapterNumber: 19, // Required
          contentType: 'PXE', // Required
          tenantObj: {
            tenantId: 'bfff63e6-3f6b-4aa9-acd8-fab7a71ee8ed',
            tenantKey: 'ff30d520-6137-46a2-b026-c5597793e3d8'
          },
          berlinTenantObject: {
            tenantId: 'bfff63e6-3f6b-4aa9-acd8-fab7a71ee8ed',
            tenantKey: 'ff30d520-6137-46a2-b026-c5597793e3d8'
          },
          currentSectionIds: [
            'a8920d6d45fc2049bb7a1ae473e02560346bd10bd-P70010169410000000000000000012CC',
            'a1df7a3cea32a09416bbc3c5a6259196709312d99'
          ] // Required
        }} // Required
        getCurrentSection={getCurrentSection} // Required
        getAuthToken={getAuthToken} // Required
        handleChatbotOpened={handleChatbotOpened}
        openBotContainer={open} // Required
        showChatBotIcon
        showUserHistory
        tutorName="Peri"
        welcomeMessage="Hello, I'm Peri, a virtual assistant. I can answer questions about Pearson policies. What can I help you with?"
        expandMode={true}
        toggleBotContainer={() => setOpen(!open)}
      />
    </div>
  );
};

ServiceNow.propTypes = {
  getPiToken: shapes.modelOf(GetPiTokenModel).isRequired
};

export default observer(inject('getPiToken')(ServiceNow));
