import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const tenantData = types.model('tenantData', {
  tenantId: types.maybeNull(types.string),
  tenantKey: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  title: types.maybeNull(types.string)
});

const TenantConfigModel = types
  .model('TenantConfigModel', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(tenantData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_TENANT_CONFIG_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_TENANT_CONFIG_DATA);
    },
    resetStoreValues() {
      const initialState = {};
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_TENANT_CONFIG_DATA);
    }
  }));

export default TenantConfigModel;
