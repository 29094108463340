import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PreviewIcon from '@mui/icons-material/Preview';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../../../../common/Loader';
import ChatgptConfig from '../../../../common/config/ChatgptConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import AutocompleteComponent from '../../common/components/Autocomplete';
import SearchButtonComponent from '../../common/components/SearchButtonComponent';
import CommonSearchModel from '../../models/CommonSearchModel';
import McqSearch from '../../models/McqSearch';
import TenantConfigModel from '../../models/TenantConfig';
import AdminPromptView from '../admin/AdminPromptView';
import DynamicTrendsComponent from './DynamicTrendsComponent';
import PVSIngestionComponent from './PVSIngestion/PVSIngestionComponent';
import DynamicDataGrid from './dataGrid/DynamicDataGrid';

const materialTheme = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const styles = (theme) => ({
  datePicker: {
    // width: '49%',
    marginTop: 'inherit'
  },
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  buttonIngestion: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  radioButton: {
    textAlign: 'center'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  },
  headingClass: {
    padding: '15px',
    display: 'flex'
  },
  searchBarClass: {
    width: '25vw'
  },
  searchHeaders: {
    paddingLeft: '10vw'
  }
});

@inject('commonSearchUtility', 'commonSearchUtilityStatus', 'mcqSearch', 'tenantConfig')
@observer
class DynamicComponent extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    commonSearchUtility: shapes.modelOf(CommonSearchModel).isRequired,
    commonSearchUtilityStatus: shapes.state.isRequired,
    mcqSearch: shapes.modelOf(McqSearch).isRequired,
    tenantConfig: shapes.modelOf(TenantConfigModel).isRequired,
    history: PropTypes.object.isRequired,
    selectedTabValue: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    const { commonSearchUtility } = props;
    this.defaultSearch = {
      bookId: '',
      userId: '',
      email: '',
      startDateTime: '',
      endDateTime: '',
      type: '',
      startDate: '',
      endDate: '',
      manifestId: '',
      ingestionStatus: ''
    };
    this.state = {
      newRequest: true,
      searchFields: {},
      searchedDataResponse: commonSearchUtility,
      ingestionDataResponse: [],
      endDisabled: true,
      loaderStatus: true,
      isbookIdRequired: false,
      bookList: [],
      inputValue: '',
      tokenValue: '',
      tenantId: '',
      tenantName: '',
      tenantTitle: '',
      showDateFields: false,
      showOutline: '1D',
      clusterState: 'cluster',
      loadPvsIngestion: false,
      showIngestion: false,
      viewType: 'mcq'
    };
  }

  componentDidMount = () => {
    const { commonSearchUtility } = this.props;
    this.setTenantDetails();
    // if (mcqSearch && mcqSearch.data.length > 0) {
    //   this.setState({
    //     bookList: mcqSearch.data
    //   });
    // }
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.GET_COMMON_SEARCH_UTILITY_RESPONSE, () => {
      const { selectedTabValue } = this.props;
      const { newRequest, searchedDataResponse, bookList, tenantName } = this.state;
      const { data } = commonSearchUtility;
      if (commonSearchUtility) {
        let combineData = [];
        if (selectedTabValue === 1) {
          const { summary, quiz, discuss } = commonSearchUtility;
          const summaryData = summary && summary.length > 0 ? summary : [];
          const quizData = quiz && quiz.length > 0 && quiz ? quiz : [];
          const discussData = discuss && discuss.length > 0 && discuss ? discuss : [];
          combineData = [...summaryData, ...quizData, ...discussData];
        } else {
          combineData = [...data];
        }
        bookList?.forEach((item) => {
          const alteredValue = item.bookId;
          if (!alteredValue) return;
          combineData.forEach((dataValue, index) => {
            if (dataValue.bookId === alteredValue) {
              combineData[index].title = item.title;
            }
          });
        });
        let finalData;
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        // TODO: Below condition for POC Only
        if (selectedTabValue === 0 || selectedTabValue === 1) {
          if (tenantName === 'pvs') {
            finalData = combineDatatoJS;
          } else if (tenantName === 'servicenow') {
            finalData = combineDatatoJS.filter(
              (item) => ['cf426e7b1bd209508f22337f034bcbe8'].includes(item.bookId) && ['answer'].includes(item.type)
            );
          } else {
            finalData = combineDatatoJS.filter(
              (item) =>
                ['rplus-60ff996c0e429e21ee07cce2'].includes(item.bookId) &&
                ['summary', 'answer', 'quiz_mcq'].includes(item.type)
            );
          }
        } else {
          finalData = combineDatatoJS;
        }
        /* eslint-enable camelcase */
        if (newRequest) {
          const sortedDate = finalData.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState({ searchedDataResponse: sortedDate });
        } else {
          const combineDataGpt = [...searchedDataResponse, ...finalData];
          const sortedDate = combineDataGpt.sort((x, y) => new Date(y.serverDateTime) - new Date(x.serverDateTime));
          this.setState(() => ({ searchedDataResponse: sortedDate }));
        }
      }
    });
    Framework.getEventManager().on(constants.GET_COMMON_SEARCH_INGESTION_RESPONSE, () => {
      const { data } = commonSearchUtility;
      const combineDatatoJS = JSON.parse(JSON.stringify(toJS([...data])));
      if (combineDatatoJS) {
        this.setState(() => ({ ingestionDataResponse: combineDatatoJS }));
      }
    });
    /* eslint-enable camelcase */
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { mcqSearch, selectedTabValue } = this.props;
    const { tokenValue } = this.state;
    if (prevState.tokenValue !== tokenValue && mcqSearch && mcqSearch.data.length === 0) {
      this.getBookData();
    }

    if (prevProps.selectedTabValue !== selectedTabValue) {
      this.handleReset();
    }
  };

  setTenantDetails = () => {
    const { history, tenantConfig } = this.props;
    const selectedTabName = history.location.pathname.split('/');
    ChatgptConfig.tenantData.forEach((item) => {
      if (item.name === selectedTabName[2]) {
        this.setState({
          tenantId: utils.getTenantId(item.name, tenantConfig),
          tenantName: item.name,
          bookList: item.titles,
          tenantTitle: item.title
        });
      }
    });
  };

  getBookData = async () => {
    const { tokenValue } = this.state;
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.GET_BOOK_DATA_URL}`, {}, { headers });
    if (response && response.data && response.data.data) {
      Framework.getStoreRegistry().getStore('mcqSearch').setResponse(response.data);
      // this.setState({
      //   bookList: response.data.data
      // });
    }
  };

  onInputChange = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged },
      isbookIdRequired: false
    });
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const { selectedTabValue } = this.props;
    const fieldChanged = {};
    fieldChanged[name] =
      selectedTabValue === 2
        ? moment(date).format(constants.YYYY_MM_DD_FORMAT)
        : moment(date).format(constants.MM_DD_YYYY_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDateTime || payload.startDate) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleReset = () => {
    const { commonSearchUtility, selectedTabValue } = this.props;
    const { tenantName } = this.state;
    if (selectedTabValue === 4 && tenantName === 'pvs') {
      this.renderContentManagementData();
      this.setState({
        searchFields: {
          ...this.defaultSearch
        },
        inputValue: '',
        ingestionDataResponse: []
      });
    } else {
      this.setState({
        endDisabled: true,
        isbookIdRequired: false,
        searchFields: {
          ...this.defaultSearch
        },
        inputValue: '',
        showOutline: '1D',
        clusterState: 'cluster',
        searchedDataResponse: [],
        ingestionDataResponse: [],
        showIngestion: false
      });
      commonSearchUtility.resetStoreValues();
    }
  };

  renderContentManagementData = async (searchVal) => {
    try {
      const { tokenValue } = this.state;
      this.setState({ loadPvsIngestion: true });
      const headers = {
        Authorization: `Bearer ${tokenValue}`
      };
      const response = await axios.post(
        `${env.EVERGREEN_API_BASE_URL}${constants.SEARCH_PVS_INGESTION_URL}`,
        {
          searchField: searchVal || ''
        },
        { headers }
      );
      if (response?.data?.length > 0) {
        this.setState({ loadPvsIngestion: false });
        /* eslint-disable */
        const pvsContentMgmtData = response?.data?.map((item) => ({
          manifestId: item.contentPublishMessage.payload.manifestId,
          courseId: item.contentPublishMessage.payload.courseId,
          updatedAt: new Date(item.updatedAt).toLocaleString(),
          statusCode: item?.status?.code
        }));
        /* eslint-disable */
        const responseObj = {
          status: 'success',
          data: pvsContentMgmtData
        };
        Framework.getStoreRegistry().getStore('commonSearchUtility').setResponse(responseObj);
      } else {
        this.setState({ loadPvsIngestion: false });
        const notification = {
          open: true,
          message: 'No Data Found',
          type: 'error'
        };
        const responseObj = {
          status: 'success',
          data: []
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
        Framework.getStoreRegistry().getStore('commonSearchUtility').setResponse(responseObj);
      }
    } catch (e) {
      const notification = {
        open: true,
        message: e?.message || e,
        type: 'error'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
      this.setState({ loadPvsIngestion: false });
    }
  };

  handleSearch = async (customPayload) => {
    const { searchFields, tenantId, tenantName, clusterState } = this.state;
    const { commonSearchUtility, selectedTabValue } = this.props;

    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      this.setState({ newRequest: true });
    }
    const payload = { ...searchFields, ...customPayload };
    if (selectedTabValue === 2) {
      let endDateChanged = new Date();
      endDateChanged = moment(endDateChanged).format(constants.YYYY_MM_DD_FORMAT);

      if ((payload && payload.startDate) || (payload && payload.bookId)) {
        if (!payload.endDate && payload.startDate) {
          payload.endDate = endDateChanged;
          this.setState({
            searchFields: { ...searchFields, endDate: endDateChanged }
          });
        }
      } else {
        payload.endDate = endDateChanged;
        payload.startDate = endDateChanged;
        this.setState({
          searchFields: { ...searchFields, startDate: endDateChanged, endDate: endDateChanged }
        });
      }
    }

    if ((selectedTabValue === 4 && tenantName === 'pvs') || payload.searchVal) {
      this.renderContentManagementData(payload.searchVal);
    }
    if (
      payload &&
      (payload.bookId ||
        payload.email ||
        payload.userId ||
        payload.type ||
        payload.startDate ||
        payload.endDate ||
        payload.startDateTime ||
        payload.endDateTime ||
        payload.manifestId ||
        payload.ingestionStatus)
    ) {
      payload.tenantId = tenantId;
      payload.tenantName = tenantName;
      payload.selectedTabValue = selectedTabValue;
      if (selectedTabValue === 2) payload.clusterState = clusterState;
      if (selectedTabValue === 4 && tenantName === 'pvs') {
        this.setState({ loadPvsIngestion: true });
        const { tokenValue } = this.state;
        const headers = {
          Authorization: `Bearer ${tokenValue}`
        };
        const response = await axios.post(
          `${env.EVERGREEN_API_BASE_URL}${constants.PVS_INGESTION_URL}`,
          {
            courseId: payload.bookId,
            manifestId: payload.manifestId,
            startDate: payload.startDateTime,
            endDate: payload.endDateTime,
            status: payload.ingestionStatus
          },
          { headers }
        );
        if (response?.data?.length > 0) {
          this.setState({ loadPvsIngestion: false });
          /* eslint-disable */
          const pvsData = response?.data?.map((item) => ({
            manifestId: item.contentPublishMessage.payload.manifestId,
            trackingId: item.trackingId,
            courseId: item.contentPublishMessage.payload.courseId,
            updatedAt: new Date(item.updatedAt).toLocaleString(),
            activities: item?.activities?.map((activity) => {
              const lastObject = activity.statusMessages[activity.statusMessages.length - 1];
              const newObject = lastObject.payload
                ? {
                    creationDt: lastObject.payload.creationDt,
                    manifestProcessingStatusCode: lastObject.payload.manifestProcessingStatusCode
                  }
                : {
                    creationDt: lastObject.creationDt,
                    manifestProcessingStatusCode: lastObject.manifestProcessingStatusCode
                  };
              return {
                ...activity,
                statusMessages: [newObject]
              };
            }),
            status: item.status
          }));
          /* eslint-disable */
          const responseObj = {
            status: 'success',
            data: pvsData
          };
          Framework.getStoreRegistry().getStore('commonSearchUtility').setIngestionResponse(responseObj);
        } else {
          this.setState({ loadPvsIngestion: false });
          const notification = {
            open: true,
            message: 'No Data Found',
            type: 'error'
          };
          const responseObj = {
            status: 'success',
            data: []
          };
          Framework.getStoreRegistry().getStore('notificationState').set(notification);
          Framework.getStoreRegistry().getStore('commonSearchUtility').setResponse(responseObj);
        }
      } else {
        commonSearchUtility.fetch(payload);
      }
    } else {
      selectedTabValue !== 3 && tenantName !== 'pvs' && this.setState({ isbookIdRequired: true });
    }
  };

  handleAscending = (dir, value) => {
    const { searchedDataResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...searchedDataResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...searchedDataResponse,
        searchedDataResponse: sorted
      });
    } else {
      const sorted = [...searchedDataResponse].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1
      );
      this.setState({
        ...searchedDataResponse,
        searchedDataResponse: sorted
      });
    }
  };

  onAutoCompleteChange = (event, newValue) => {
    const { searchFields } = this.state;
    this.setState({
      searchFields: {
        ...searchFields,
        bookId: newValue !== null ? newValue.bookId : ''
      },
      isbookIdRequired: false
    });
  };

  handleSelectChange = (e) => {
    const { commonSearchUtility } = this.props;
    this.setState({
      clusterState: e.target.value
    });
    commonSearchUtility.resetStoreValues();
  };

  handleDateclicker = (event, value) => {
    const { searchFields } = this.state;
    const daysvalue = event.target.innerText;
    let startDate = new Date();
    let endDate = new Date();
    switch (daysvalue) {
      case '1d':
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '3d':
        startDate.setDate(startDate.getDate() - 2);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '1w':
        startDate.setDate(startDate.getDate() - 6);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      default:
    }
    startDate = moment(startDate).format(constants.YYYY_MM_DD_FORMAT);
    endDate = moment(endDate).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, startDate, endDate }
    });
  };

  trendsDataFetch = (value) => {
    const { showDateFields } = this.state;
    if (value === 'custom') {
      this.setState({
        showOutline: value,
        showDateFields: !showDateFields
      });
    }
  };

  setAutocompleteLable = () => {
    const { tenantName } = this.state;
    let lableVal = 'Book Id';
    if (tenantName === 'servicenow') {
      lableVal = 'Knowledge Based Group';
    }
    if (tenantName === 'pvs') {
      lableVal = 'Course Id';
    }
    return lableVal;
  };

  handleIngestionClick = () => {
    this.setState({ showIngestion: true });
  };

  handleBackClick = () => {
    this.setState({ showIngestion: false });
    this.handleReset();
  };

  handleViewTypeChange = (e) => {
    this.setState({
      ...this.state,
      viewType: e.target.value
    });
  };

  render() {
    const {
      classes,
      commonSearchUtilityStatus,
      selectedTabValue,
      commonSearchUtility: { isNextPageKey },
      history
    } = this.props;
    const {
      searchFields,
      endDisabled,
      searchedDataResponse,
      ingestionDataResponse,
      loaderStatus,
      bookList,
      isbookIdRequired,
      inputValue,
      tokenValue,
      showDateFields,
      clusterState,
      showOutline,
      tenantName,
      tenantTitle,
      loadPvsIngestion,
      tenantId,
      showIngestion,
      viewType
    } = this.state;

    const activeSession = utils.getSessionData();
    if (activeSession !== null && tokenValue === '') {
      const token = utils.getToken();
      this.setState({
        tokenValue: token
      });
    }
    return (
      <>
        {tenantName === 'pvs' && history.location.pathname.split('/')[3] === 'promptmgt' ? (
          <AdminPromptView />
        ) : (
          <>
            <Paper>
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h5" align="center" gutterBottom spacing={9}>
                    {constants.CHAT_CRITERIA_TEXT}
                  </Typography>
                </Box>
                {selectedTabValue === 2 ? (
                  <DynamicTrendsComponent
                    showDateFields={showDateFields}
                    searchFields={searchFields}
                    endDisabled={endDisabled}
                    showOutline={showOutline}
                    clusterState={clusterState}
                    bookList={bookList}
                    inputValue={inputValue}
                    handleSelectChange={this.handleSelectChange}
                    trendsDataFetch={this.trendsDataFetch}
                    handleDateclicker={this.handleDateclicker}
                    onInputChange={this.onInputChange}
                    onAutoCompleteChange={this.onAutoCompleteChange}
                    handleDateChange={this.handleDateChange}
                    tenantName={tenantName}
                  />
                ) : (
                  <>
                    {history.location.pathname.split('/')[3] !== 'titlesearch' ? (
                      <>
                        <Grid container direction="row">
                          <Grid item xs={1} />
                          <Grid item xs={10}>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}>
                              <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                                <AutocompleteComponent
                                  options={bookList}
                                  onAutoCompleteChange={this.onAutoCompleteChange}
                                  onInputChange={this.onInputChange}
                                  stateVal={inputValue}
                                  errorVal={isbookIdRequired}
                                  name="bookId"
                                  label={this.setAutocompleteLable()}
                                />
                                {isbookIdRequired && (
                                  <FormHelperText style={{ color: 'red' }}>
                                    Please select any {this.setAutocompleteLable()}
                                  </FormHelperText>
                                )}
                              </FormControl>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <ThemeProvider theme={materialTheme}>
                                  <KeyboardDatePicker
                                    className={classes.datePicker}
                                    format={constants.MM_DD_YYYY_FORMAT}
                                    autoOk
                                    size="small"
                                    variant="inline"
                                    style={{ minWidth: '32%' }}
                                    inputVariant="outlined"
                                    disableFuture
                                    value={searchFields.startDateTime || null}
                                    label="Start Date"
                                    onChange={(date) => this.handleDateChange(date, 'startDateTime')}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                  />
                                  <KeyboardDatePicker
                                    className={classes.datePicker}
                                    format={constants.MM_DD_YYYY_FORMAT}
                                    autoOk
                                    variant="inline"
                                    size="small"
                                    style={{ minWidth: '32%' }}
                                    inputVariant="outlined"
                                    disableFuture
                                    value={searchFields.endDateTime || null}
                                    label="End Date"
                                    onChange={(date) => this.handleDateChange(date, 'endDateTime')}
                                    disabled={endDisabled}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                  />
                                </ThemeProvider>
                              </MuiPickersUtilsProvider>
                            </Box>
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                        <br />
                        <Grid container direction="row">
                          <Grid item xs={1} />
                          <Grid item xs={10} direction="row">
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0px 32px' }}>
                              <TextField
                                label="User ID"
                                margin="dense"
                                name="userId"
                                onChange={(event) => this.handleChange(event)}
                                style={{ minWidth: '32%' }}
                                value={searchFields.userId}
                                variant="outlined"
                              />
                              <TextField
                                label="Email ID"
                                margin="dense"
                                type="email"
                                style={{ minWidth: '32%' }}
                                name="email"
                                onChange={(event) => this.handleChange(event)}
                                value={searchFields.email}
                                variant="outlined"
                              />
                              <FormControl sx={{ mt: 1, width: '32%' }} size="small">
                                <InputLabel id="demo-simple-select-autowidth-label">Type</InputLabel>
                                <Select
                                  label="Type"
                                  name="type"
                                  onChange={(event) => this.handleChange(event)}
                                  value={searchFields.type || ''}
                                >
                                  {tenantName === 'servicenow' ? (
                                    <MenuItem value="answer">Explain</MenuItem>
                                  ) : (
                                    ChatgptConfig.filterByChats
                                      .filter((value, index) => index <= 2)
                                      .map((value, index) => (
                                        <MenuItem
                                          divider={index === 2}
                                          value={
                                            (index === 1 && (index === 1 ? 'answer' : value.name)) ||
                                            (index === 2 && (index === 2 ? 'quiz_mcq' : value.name)) ||
                                            value.name
                                          }
                                        >
                                          {value.label}
                                        </MenuItem>
                                      ))
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                        <br />
                      </>
                    ) : (
                      <>
                        {!showIngestion ? (
                          <>
                            <Box display="flex" style={{ justifyContent: 'flex-end', marginRight: '30px' }}>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PreviewIcon />}
                                onClick={this.handleIngestionClick}
                                className={classes.buttonIngestion}
                              >
                                Ingestion
                              </Button>
                            </Box>
                            <Box display="flex" style={{ justifyContent: 'center', marginLeft: '7%' }}>
                              <Grid container direction="row" alignItems="center" className={classes.searchHeaders}>
                                <Typography className={classes.headingClass}>Course Search</Typography>
                                <TextField
                                  id="outlined-basic"
                                  label="Course Id, ManifestId"
                                  variant="outlined"
                                  name="searchVal"
                                  onChange={(event) => this.handleChange(event)}
                                  className={classes.searchBarClass}
                                  value={searchFields.searchVal}
                                />
                                <Grid item xs={6} alignItems="center" className={classes.headingClass}>
                                  <ThemeProvider theme={materialTheme}>
                                    <Box sx={{ minWidth: 120 }}>
                                      <FormControl fullWidth>
                                        <RadioGroup
                                          name="viewType"
                                          value={viewType}
                                          onChange={this.handleViewTypeChange}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                          }}
                                        >
                                          <FormControlLabel
                                            value="mcq"
                                            control={<Radio color="primary" />}
                                            label="Quiz"
                                          />
                                          <FormControlLabel
                                            value="summary"
                                            control={<Radio color="primary" />}
                                            label="Summary"
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Box>
                                  </ThemeProvider>
                                </Grid>
                              </Grid>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <IconButton onClick={this.handleBackClick}>
                                <ArrowBackIosIcon />
                              </IconButton>
                            </Box>
                            <Grid container direction="row">
                              <Grid item xs={1} />
                              <Grid item xs={10}>
                                <Box
                                  sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', p: '0px 33px' }}
                                >
                                  <FormControl sx={{ minWidth: '32%', maxWidth: '90%' }} size="small">
                                    <AutocompleteComponent
                                      options={bookList}
                                      onAutoCompleteChange={this.onAutoCompleteChange}
                                      onInputChange={this.onInputChange}
                                      stateVal={inputValue}
                                      name="bookId"
                                      label={this.setAutocompleteLable()}
                                    />
                                  </FormControl>
                                  <TextField
                                    label="Manifest ID"
                                    margin="dense"
                                    name="manifestId"
                                    onChange={(event) => this.handleChange(event)}
                                    style={{ minWidth: '32%', marginTop: '2px' }}
                                    value={searchFields.manifestId}
                                    variant="outlined"
                                  />
                                  <FormControl sx={{ width: '32%' }} size="small">
                                    <InputLabel id="ingestionStatus">Ingestion Status</InputLabel>
                                    <Select
                                      label="Ingestion Status"
                                      name="ingestionStatus"
                                      onChange={(event) => this.handleChange(event)}
                                      value={searchFields.ingestionStatus || ''}
                                    >
                                      <MenuItem value="Received">Received</MenuItem>
                                      <MenuItem value="InProgress">InProgress</MenuItem>
                                      <MenuItem value="Completed">Completed</MenuItem>
                                      <MenuItem value="CompletedWithErrors">CompletedWithErrors</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={1} />
                            </Grid>
                            <br />
                            <Grid container direction="row">
                              <Grid item xs={1} />
                              <Grid item xs={10} direction="row">
                                <Box sx={{ display: 'flex', justifyContent: 'center', p: '0px 32px' }}>
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <ThemeProvider theme={materialTheme}>
                                      <KeyboardDatePicker
                                        className={classes.datePicker}
                                        format={constants.MM_DD_YYYY_FORMAT}
                                        autoOk
                                        size="small"
                                        variant="inline"
                                        style={{ minWidth: '32%', marginRight: '15px' }}
                                        inputVariant="outlined"
                                        disableFuture
                                        value={searchFields.startDateTime || null}
                                        label="Start Date"
                                        onChange={(date) => this.handleDateChange(date, 'startDateTime')}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date'
                                        }}
                                      />
                                      <KeyboardDatePicker
                                        className={classes.datePicker}
                                        format={constants.MM_DD_YYYY_FORMAT}
                                        autoOk
                                        variant="inline"
                                        size="small"
                                        style={{ minWidth: '32%' }}
                                        inputVariant="outlined"
                                        disableFuture
                                        value={searchFields.endDateTime || null}
                                        label="End Date"
                                        onChange={(date) => this.handleDateChange(date, 'endDateTime')}
                                        disabled={endDisabled}
                                        KeyboardButtonProps={{
                                          'aria-label': 'change date'
                                        }}
                                      />
                                    </ThemeProvider>
                                  </MuiPickersUtilsProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={1} />
                            </Grid>
                            <br />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
              <SearchButtonComponent
                classes={classes}
                handleSearch={this.handleSearch}
                handleReset={this.handleReset}
              />
            </Paper>
            <LoadingHandler
              loading={commonSearchUtilityStatus.isPending || loadPvsIngestion}
              isError={
                commonSearchUtilityStatus.isError &&
                commonSearchUtilityStatus.error &&
                commonSearchUtilityStatus.error.status === 401 &&
                commonSearchUtilityStatus.error.status === 500
              }
              content={
                (searchedDataResponse && searchedDataResponse.length > 0) ||
                (ingestionDataResponse && ingestionDataResponse.length > 0) ? (
                  <Box>
                    <br />
                    {history.location.pathname.split('/')[3] === 'titlesearch' && showIngestion ? (
                      <>
                        <PVSIngestionComponent data={ingestionDataResponse} />
                        <br />
                      </>
                    ) : (
                      <DynamicDataGrid
                        selectedTab={selectedTabValue}
                        gridData={searchedDataResponse}
                        handleSearch={this.handleSearch}
                        handleAscending={this.handleAscending}
                        isNextPageKey={isNextPageKey}
                        clusterState={clusterState}
                        tenantName={tenantName}
                        tenantTitle={tenantTitle}
                        tenantId={tenantId}
                        viewType={viewType}
                      />
                    )}
                  </Box>
                ) : (
                  <>
                    <br />
                    <Paper>
                      <br />
                      <Box>
                        <br />
                        <Typography
                          variant="h4"
                          align="center"
                          gutterBottom
                          spacing={9}
                          className={classes.title}
                          style={{ p: 5, mt: 25 }}
                        >
                          {constants.CHAT_FETCH}
                        </Typography>
                      </Box>
                    </Paper>
                  </>
                )
              }
              loadingContent={<Loader loaderStatus={loaderStatus} />}
              errorContent={<StateErrorDisplay error={commonSearchUtilityStatus.error} showDetails />}
            />
          </>
        )}
      </>
    );
  }
}

export default withStyles(styles)(DynamicComponent);
