/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles,
  Checkbox
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import PreviewIcon from '@mui/icons-material/Preview';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Link from '@mui/material/Link';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import * as constants from '../constants';
import utils from '../utils';
import TooltipCustom from './TooltipCustom';

const styles = () => ({
  tableCustomWidth: {
    width: '2px'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  radioButton: {
    textAlign: 'center'
  },
  root: {
    width: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  columnWidth: {
    width: 200
  },
  restorecolumnWidth: {
    width: 20,
    textAlign: 'center',
    padding: '0px'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  effectivenessOutputColumn: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    position: 'absolute',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '22%'
  },
  promptTypeColumn: {
    wordWrap: 'break-word'
  },
  previewColumnWidth: {
    padding: '0px'
  }
});

const TableComponent = (props) => {
  const {
    columns,
    data,
    classes,
    onColumnClick,
    order,
    onRowClick,
    directionValue,
    userEmailDetails,
    onMouseEnter,
    archived,
    handleArchivedclick,
    disableRepublish,
    handleClone,
    showCloneIcon,
    selectedValue = '',
    showSelectColumn = false,
    isFromCommonDataGrid = false,
    // status = '',
    // handleEvaluate,
    isFromPVSAssesment = false,
    handleAssessment,
    setUserId,
    userIdChecked,
    isFromCharts,
    showColumnHeaderToolTip,
    showTooltipTitle
  } = props;
  const LightTooltip = styled(({ className, ...params }) => <Tooltip {...params} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
      }
    })
  );

  const renderRowsData = (row, column) => {
    const highlightRow = () => {
      let styleVal = { bgColor: 'transparent', textColor: '#6a7070' };
      // const rowData = ['Answer Relevance', 'Context Relevance', 'Correctness', 'Groundedness'].includes(column.key);
      if (Array.isArray(row.feedbacks)) {
        row.feedbacks.forEach((item) => {
          if (item[column.key] !== null) {
            if (
              (item[column.key] < 3 && item[column.key] > 0) ||
              (column.key === constants.F1_SCORE_NAME && item[column.key] !== 1)
            ) {
              styleVal = { bgColor: '#FF0000', textColor: 'black' };
            }
            if (item[column.key] >= 3 && item[column.key] < 5) {
              styleVal = { bgColor: ' #FFA500', textColor: 'black' };
            }
            if (item[column.key] === 5 || (column.key === constants.F1_SCORE_NAME && item[column.key] === 1)) {
              styleVal = { bgColor: '#5fb65f', textColor: 'black' };
            }
          }
        });
      }
      return styleVal;
    };

    const renderSubRowsData = (rows, colVals) => {
      if (Array.isArray(rows[colVals.key])) {
        if (colVals.key === constants.PROMPT_START_COLUMN_NAME || colVals.key === constants.PROMPT_END_COLUMN_NAME) {
          return (
            <>
              {/* eslint-disable-next-line */}
              <Typography
                className={classes.effectivenessOutputColumn}
                style={{ width: '8%', height: '20px' }}
                dangerouslySetInnerHTML={{ __html: rows[colVals.key][0] && rows[colVals.key][0].content }}
              />
            </>
          );
        }
        return (
          /* eslint-disable react/no-danger */
          <div
            className={classes.effectivenessOutputColumn}
            style={{ width: '8%', height: '35px' }}
            dangerouslySetInnerHTML={{ __html: rows[colVals.key][0] && rows[colVals.key][0].content }}
          />
          /* eslint-disable react/no-danger */
        );
      }
      if (colVals.key === 'reasonForDisLike') {
        return (
          <>
            {Array.isArray(rows[colVals.key]) &&
              rows[colVals.key].map((value, index) => (
                <span>
                  {value}
                  {index !== rows[colVals.key].length - 1 && <b>{' | '}</b>}
                </span>
              ))}
          </>
        );
      }
      if (isFromCommonDataGrid) {
        if (colVals.key === 'question') {
          const dataType =
            [
              constants.CHAT_UTILITY_ANSWER,
              constants.DISCUSS,
              constants.CHAT_INTENT,
              constants.PROBLEM_SOLVE,
              constants.SUMMARY_EXPLAIN,
              constants.CONTEXT_IDENTIFIER_USER_SELECTION,
              constants.EXPLAIN_STAND_ALONE,
              constants.SALUTATION,
              constants.TOPIC,
              constants.TYPE_QUIZ
            ].includes(rows.type) && rows.type !== rows[colVals.key]
              ? rows[colVals.key]
              : '';
          return <>{dataType}</>;
        }
        if (rows[colVals.key] === 'answer' || rows[colVals.key] === 'discuss') {
          return 'explain';
        }
        return rows[colVals.key];
      }
      if (colVals.key === 'output') {
        return rows[colVals.key].replace(/<\/?[^>]+(>|$)/g, ' ');
      }
      if (
        (showCloneIcon || archived || isFromCommonDataGrid) &&
        (colVals.key === 'type' || colVals.key === 'comments')
      ) {
        return (
          <>
            {/* eslint-disable-next-line */}
            <div
              style={{ width: column.columnWidth }}
              className={classes.promptTypeColumn}
              dangerouslySetInnerHTML={{ __html: rows[colVals.key] }}
            />
          </>
        );
      }
      if (isFromPVSAssesment && colVals.key === 'assessmentName') {
        return (
          <Link variant="body2" style={{ cursor: 'pointer' }} onClick={(event) => handleAssessment(event, row)}>
            {rows[colVals.key]}
          </Link>
        );
      }
      return rows[colVals.key];
    };

    return (
      <TableCell style={{ width: column.columnWidth, backgroundColor: highlightRow().bgColor }}>
        <Typography
          className={column.key === 'output' ? classes.effectivenessOutputColumn : classes.tableText}
          style={{ color: highlightRow().textColor }}
        >
          {row[column.key] === undefined && Array.isArray(row.feedbacks)
            ? row.feedbacks.filter((item) => item[column.key] !== null).map((item) => item[column.key])
            : renderSubRowsData(row, column)}
        </Typography>
      </TableCell>
    );
  };

  const handleUserIdCheck = (item, checkedData) => {
    setUserId(item, checkedData);
  };

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {!showSelectColumn && (
              <TableCell align="center" className={classes.tableCustomWidth}>
                <Typography variant="h6" className={classes.columnStyle}>
                  {constants.SELECT}
                </Typography>
              </TableCell>
            )}
            {columns.map((column) => (
              <>
                {showColumnHeaderToolTip ? (
                  <LightTooltip title={showTooltipTitle(column.name)} arrow>
                    <TableCell align="center" sortDirection="asc" style={{ width: column.columnWidth }}>
                      <Typography variant="h6" className={classes.columnStyle}>
                        {column.name}
                        <TableSortLabel
                          onClick={() => onColumnClick(column.key, archived)}
                          active
                          direction={directionValue === column.key && order === 'DSC' ? 'asc' : 'desc'}
                          sx={{
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiTableSortLabel-root:hover': {
                              cursor: 'pointer'
                            }
                          }}
                        />
                      </Typography>
                    </TableCell>
                  </LightTooltip>
                ) : (
                  <TableCell align="center" sortDirection="asc" style={{ width: column.columnWidth }}>
                    <Typography variant="h6" className={classes.columnStyle}>
                      {column.name}
                      <TableSortLabel
                        onClick={() => onColumnClick(column.key, archived)}
                        active
                        direction={directionValue === column.key && order === 'DSC' ? 'asc' : 'desc'}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </Typography>
                  </TableCell>
                )}
              </>
            ))}
            {archived && (
              <TableCell align="center" className={classes.tableCustomWidth}>
                <Typography variant="h6" className={classes.columnStyle}>
                  Restore
                </Typography>
              </TableCell>
            )}
            {showCloneIcon && (
              <>
                <TableCell align="center" className={classes.tableCustomWidth}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    Clone
                  </Typography>
                </TableCell>
                {/* {(status === 'Draft' || status === 'Experiment') && (
                  <TableCell align="center" className={classes.previewColumnWidth}>
                    <Typography variant="h6" className={classes.columnStyle}>
                      Evaluate
                    </Typography>
                  </TableCell>
                )} */}
              </>
            )}
            {isFromPVSAssesment && (
              <TableCell align="center" className={classes.previewColumnWidth}>
                <Typography variant="h6" className={classes.columnStyle} />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <>
              <TableRow
                style={{
                  cursor: 'pointer',
                  backgroundColor: selectedValue === index ? '#f4f6f8' : '',
                  transition: 'background-color 0.3s ease'
                }}
                sx={{ '&:hover': { backgroundColor: '#f0f0f0' } }}
                onClick={() => onRowClick(row, index)}
              >
                {!showSelectColumn && (
                  <TableCell className={classes.radioButton}>
                    <Radio size="small" checked={selectedValue === index} color="primary" name="radioButton" />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <>
                    {column.key === 'userId' ? (
                      <TableCell
                        className={classes.tableFilterTooltipWidth}
                        onMouseEnter={() => onMouseEnter(row[column.key])}
                        key={index}
                      >
                        <LightTooltip
                          title={
                            userEmailDetails
                              ? Object.keys(userEmailDetails).map((item) => {
                                  let poperValue = <></>;
                                  if (item === 'firstName') {
                                    poperValue = (
                                      <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
                                        <b>First Name : </b>
                                        {userEmailDetails[item]}
                                      </Typography>
                                    );
                                  } else if (item === 'lastName') {
                                    poperValue = (
                                      <Typography gutterBottom>
                                        <b>Last Name : </b>
                                        {userEmailDetails[item]}
                                      </Typography>
                                    );
                                  } else if (item === 'email') {
                                    poperValue = (
                                      <Typography gutterBottom>
                                        <b>Email : </b>
                                        {userEmailDetails[item]}
                                      </Typography>
                                    );
                                  } else if (!isFromCharts && item === 'userId') {
                                    poperValue = (
                                      <Typography gutterBottom>
                                        <b>Filter results by this User Id : </b>
                                        <Checkbox
                                          defaultChecked
                                          checked={userIdChecked}
                                          onChange={() => handleUserIdCheck(item, row.userId)}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          color="default"
                                          sx={{
                                            color: 'blue',
                                            '&.Mui-checked': {
                                              color: 'blue'
                                            }
                                          }}
                                        />
                                        {/* {userEmailDetails[item]} */}
                                      </Typography>
                                    );
                                  }
                                  return poperValue;
                                })
                              : 'Loading Data ...'
                          }
                        >
                          <Typography className={classes.tableText}>{utils.displayUserId(row)}</Typography>
                        </LightTooltip>
                      </TableCell>
                    ) : (
                      renderRowsData(row, column)
                    )}
                  </>
                ))}
                {archived && (
                  <TableCell className={classes.restorecolumnWidth} key={index}>
                    <Button onClick={(event) => handleArchivedclick(event, row)} disabled={disableRepublish}>
                      <TooltipCustom title="Restore">
                        <RestartAltIcon />
                      </TooltipCustom>
                    </Button>
                  </TableCell>
                )}
                {showCloneIcon && (
                  <>
                    <TableCell className={classes.restorecolumnWidth} key={index}>
                      <Button onClick={(event) => handleClone(event, row)} disabled={disableRepublish}>
                        <TooltipCustom title="Clone">
                          <ContentCopyIcon />
                        </TooltipCustom>
                      </Button>
                    </TableCell>
                    {/* {(status === 'Draft' || status === 'Experiment') && (
                      <TableCell className={classes.previewColumnWidth} key={index}>
                        <Button onClick={(event) => handleEvaluate(event, row)} disabled={disableRepublish}>
                          <TooltipCustom title="Evaluate">
                            <PreviewIcon />
                          </TooltipCustom>
                        </Button>
                      </TableCell>
                    )} */}
                  </>
                )}
                {isFromPVSAssesment && (
                  <TableCell className={classes.previewColumnWidth} key={index}>
                    <Button>
                      <TooltipCustom title="Action">
                        <MoreVertIcon />
                      </TooltipCustom>
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onColumnClick: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  directionValue: PropTypes.string.isRequired,
  userEmailDetails: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  showSelectColumn: PropTypes.bool.isRequired,
  isFromCommonDataGrid: PropTypes.bool.isRequired,
  archived: PropTypes.bool,
  disableRepublish: PropTypes.bool,
  handleArchivedclick: PropTypes.func,
  handleClone: PropTypes.func,
  showCloneIcon: PropTypes.bool,
  status: PropTypes.string,
  handleEvaluate: PropTypes.func,
  isFromPVSAssesment: PropTypes.bool,
  handleAssessment: PropTypes.func,
  setUserId: PropTypes.func.isRequired,
  userIdChecked: PropTypes.bool.isRequired,
  isFromCharts: PropTypes.bool,
  showColumnHeaderToolTip: PropTypes.bool,
  showTooltipTitle: PropTypes.func
};

TableComponent.defaultProps = {
  archived: false,
  disableRepublish: false,
  handleArchivedclick: () => {},
  handleClone: () => {},
  showCloneIcon: false,
  status: '',
  handleEvaluate: () => {},
  isFromPVSAssesment: false,
  handleAssessment: () => {},
  isFromCharts: false,
  showColumnHeaderToolTip: false,
  showTooltipTitle: () => {}
};

export default withStyles(styles)(TableComponent);
