/* eslint-disable react/prop-types */
import { LoadingHandler } from '@greenville/framework';
import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
// import Asset from '../../models/Asset';
import TOCDisplay from '../TOCDisplay';
import Loader from '../../../../common/Loader';

const ChatDataToc = (props) => {
  const { assetTOC, assetTocStatus, match, viewType, tenantName } = props;
  const tocBookId = tenantName ? match.url.split('/')[5] : match.params.bookId;
  useEffect(() => {
    if (tenantName) {
      assetTOC.fetch({ bookId: tocBookId, tenantName });
    } else {
      assetTOC.fetch({ bookId: tocBookId });
    }
  }, []);
  return (
    <>
      <LoadingHandler
        loading={assetTocStatus.isPending}
        loadingContent={<Loader />}
        content={<TOCDisplay assetTOC={assetTOC} tocBookId={tocBookId} viewType={viewType} tenantName={tenantName} />}
      />
    </>
  );
};

export default inject('assetTOC', 'assetTocStatus')(observer(ChatDataToc));
