import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Framework, { shapes, LoadingHandler } from '@greenville/framework';
import TablePagination from '@mui/material/TablePagination';
// import { styled } from '@mui/material/styles';
// import Popover from '@mui/material/Popover';
// import { inject, observer } from 'mobx-react';
// import { Pagination, Stack, Table } from '@mui/material';
import { Table } from '@mui/material';
import { toJS } from 'mobx';
// import Framework, { shapes } from '@greenville/framework';
import {
  //   Box,
  Button,
  Radio,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
  Box,
  RadioGroup,
  FormControlLabel,
  // MenuItem,
  FormControl,
  TableSortLabel,
  withStyles
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Loader from '../../../../../common/Loader';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import ChatDialogComponent from './ChatDialogComponent';f
// import ResponseModel from '../../models/Response';
// import ChatgptUtilityIESUser from '../../models/ChatgptIESUser';
// import ChatgptUtilityMapping from '../../models/ChatgptMapping';
import * as constants from '../../../../../common/constants';
// import LinkMenu from '../../../../../common/components/linkMenu';
import McqSearch from '../../../models/McqSearch';
import ExportToCSV from '../../../common/components/ExportToCSV';

// const themes = createTheme({
//   palette: {
//     primary: {
//       light: '#047a9c',
//       main: '#005d83',
//       dark: '#003558',
//       contrastText: '#ffffff'
//     }
//   },
//   overrides: {
//     MuiButton: {
//       root: {
//         borderRadius: 30
//       }
//     }
//   }
// });

const styles = () => ({
  tableCustomWidth: {
    width: '2px'
  },
  tableDateWidth: {
    width: '190px'
  },
  tableBookWidth: {
    width: '25%'
  },
  tableTitleWidth: {
    width: '25%'
  },
  tableFeedWidth: {
    width: '140px'
  },
  tableReasonWidth: {
    width: '200px'
  },
  tableCommentsWidth: {
    width: '160px'
  },
  tableTypeWidth: {
    width: '25%'
  },
  tableUserWidth: {
    width: '160px'
  },
  tableUserIdWidth: {
    width: '25%'
  },
  tableFilterDateWidth: {
    width: '190px'
  },
  tableFilterTextWidth: {
    width: '150px'
  },
  tableFilterFeedWidth: {
    width: '150px'
  },
  tableFilterReasonWidth: {
    width: '180px'
  },
  tableFilterTooltipWidth: {
    width: '100px'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  tableTypeText: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  radioButton: {
    textAlign: 'center'
  },
  buttonStyle: {
    cursor: 'pointer',
    width: '170px !important',
    margin: '10px',
    fontSize: '14px',
    background: '#005d83 !important'
  },
  root: {
    width: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  tableTextAlongWidth: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: 160
  },
  columnWidth: {
    width: 200
  },
  contentText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    width: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  headingClass: {
    padding: '15px',
    display: 'flex'
  },
  searchBarClass: {
    width: '25vw'
  },
  searchHeaders: {
    paddingLeft: '10vw'
  },
  paginationStyle: {
    display: 'ruby-text',
    float: 'right'
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

/* const paths = [{ title: 'chatgptutility', path: '/chatgptutility' }, { title: 'Titlesearch' }];

const handleBreadcrumbClick = () => {
  // console.log('am clicked', path);
}; 

const breadcrumbStyles = {
  color: '#252525',
  fontSize: '16px',
  fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
  fontWeight: '400',
  lineHeight: '1.25'
};

const activeBreadcrumbStyles = {
  fontWeight: 'bold',
  color: '#252525'
};
*/
// const createData = (name, calories, fat, carbs, protein) => {
//   return { name, calories, fat, carbs, protein };
// };

// /* eslint-disable no-shadow */

const aiAdminContentManagementHeaders = [
  {
    key: 'bookId',
    name: constants.BOOK_ID
  },
  {
    key: 'title',
    name: constants.TITLE
  },
  {
    key: 'author',
    name: constants.CHAT_AUTHOR
  },
  {
    key: 'status',
    name: constants.GPT_STATUS
  }
];
function TitleMcqSearch(props) {
  const { classes, mcqSearch, BookSearchService } = props;
  const [rows, setRows] = useState([]);
  const [viewType, setViewType] = useState('mcq');
  const [searchField, setSearchField] = useState('');
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // const [fromValue, setFromValue] = useState(0);
  const [page, setPage] = useState(0);

  const handleSearch = () => {
    const payload = searchField ? { searchField } : '';
    mcqSearch.fetch(payload);
  };
  useEffect(() => {
    handleSearch();
    Framework.getEventManager().on(constants.SET_MCQ_SEARCH_DATA, () => {
      const { data } = mcqSearch;
      if (data.length > 0) {
        setRows(data);
      }
    });
  }, []);

  const handleOnclick = (row) => {
    const { bookId } = row;
    Framework.redirectTo(`/chatgptutility/aistudytools/${viewType}/view/${bookId}`);
  };

  const handleChange = (event) => {
    setViewType(event.target.value);
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...rows].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase().trim() > b[value].toLowerCase().trim() ? 1 : -1
      );
      setRows(sorted);
    } else {
      const sorted = [...rows].sort((a, b) =>
        a[value] && b[value] && a[value].toLowerCase().trim() < b[value].toLowerCase().trim() ? 1 : -1
      );
      setRows(sorted);
    }
  };
  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const setFromToValueForPagination = (from, to, count) => {
    return `${from}-${to} of ${count}`;
  };

  const handleChangePage = (event, newPage) => {
    // window.scrollTo({
    //   behavior: 'smooth',
    //   top: 200
    // });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <LoadingHandler
        loading={BookSearchService.isPending}
        loadingContent={<Loader />}
        content={
          <>
            {/*         <LinkMenu
          paths={paths}
          separator="/"
          onClick={handleBreadcrumbClick}
          styles={breadcrumbStyles}
          activeStyle={activeBreadcrumbStyles}
        />
        <br /> */}
            <Paper>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container direction="row" alignItems="center" className={classes.searchHeaders}>
                  <Typography className={classes.headingClass}>Title Search</Typography>
                  <TextField
                    id="outlined-basic"
                    label="Title Name, Author, BookId"
                    variant="outlined"
                    onChange={(e) => setSearchField(e.target.value)}
                    className={classes.searchBarClass}
                    value={searchField}
                  />
                  <Grid item xs={6} alignItems="center" className={classes.headingClass}>
                    <ThemeProvider theme={themes}>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="mcq"
                            name="radio-buttons-group"
                            value={viewType}
                            onChange={handleChange}
                            defaultChecked
                            style={{
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            <FormControlLabel value="mcq" control={<Radio color="primary" />} label="Quiz" />
                            <FormControlLabel value="summary" control={<Radio color="primary" />} label="Summary" />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Button
                        size="small"
                        style={{ width: '50px' }}
                        variant="contained"
                        onClick={() => handleSearch()}
                        color="primary"
                        className={classes.buttonStyle}
                      >
                        {constants.SEARCH}
                      </Button>
                      {/* <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-view-type"
                        id="select-view-type"
                        value={viewType}
                        label="View type"
                        onChange={handleChange}
                      >
                        <MenuItem value="mcq">Quiz</MenuItem>
                        <MenuItem value="summary">Summary</MenuItem>
                      </Select>
                    </FormControl>
                  </Box> */}
                    </ThemeProvider>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <br />
            <ExportToCSV
              headers={aiAdminContentManagementHeaders}
              data={toJS(rows)}
              fileTitle={constants.CONTENT_MANAGEMENT_EXPORT_FILE_NAME}
            />
            <TableContainer className={classes.root} component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.tableCustomWidth}>
                      <Typography variant="h6" className={classes.columnStyle}>
                        {constants.SELECT}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" className={classes.tableTitleWidth}>
                      <Typography variant="h6" className={classes.columnStyle}>
                        {constants.TITLE}
                      </Typography>
                      <TableSortLabel
                        onClick={() => sorting('title')}
                        active
                        direction={setDirectionValue === 'title' && SetOrder === 'DSC' ? 'asc' : 'desc'}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" className={classes.tableBookWidth}>
                      <Typography variant="h6" className={classes.columnStyle}>
                        {constants.BOOK_ID}
                      </Typography>
                      <TableSortLabel
                        onClick={() => sorting('bookId')}
                        active
                        direction={directionValue === 'bookId' && order === 'DSC' ? 'asc' : 'desc'}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" className={classes.tableTypeWidth}>
                      <Typography variant="h6" className={classes.tableTypeText}>
                        {constants.PRODUCT_TYPE}
                      </Typography>
                      <TableSortLabel
                        onClick={() => sorting('type')}
                        active
                        direction={directionValue === 'type' && order === 'DSC' ? 'asc' : 'desc'}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" className={classes.tableUserIdWidth}>
                      <Typography variant="h6" className={classes.columnStyle}>
                        {constants.CHAT_AUTHOR}
                      </Typography>
                      <TableSortLabel
                        onClick={() => sorting('author')}
                        active
                        direction={directionValue === 'author' && order === 'DSC' ? 'asc' : 'desc'}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" className={classes.tableUserWidth}>
                      <Typography variant="h6" className={classes.columnStyle}>
                        {constants.BOOK_STATUS_COLUMN_NAME}
                      </Typography>
                      <TableSortLabel
                        onClick={() => sorting('status')}
                        active
                        direction={directionValue === 'status' && order === 'DSC' ? 'asc' : 'desc'}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiTableSortLabel-root:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {rows &&
                      rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow
                          key={row.bookId}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, textAlign: 'center' }}
                          onClick={() => handleOnclick(row)}
                        >
                          <TableCell className={classes.radioButton} align="center">
                            <Radio size="small" color="primary" name="radioButton" />
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row.title}
                          </TableCell>
                          <TableCell align="center">{row.bookId}</TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                          <TableCell align="center">{row.author}</TableCell>
                          {/* <TableCell align="center">{row.protein}</TableCell> */}
                          <TableCell align="center">{row.status}</TableCell>
                        </TableRow>
                      ))}
                  </>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={rows.length}
              labelDisplayedRows={({ from, to, count }) =>
                useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes.paginationStyle}
            />
            <br />
          </>
        }
      />
    </>
  );
}

TitleMcqSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  mcqSearch: shapes.modelOf(McqSearch).isRequired,
  BookSearchService: shapes.state.isRequired
};

// export default withStyles(styles)(
//   observer(
//     inject(
//       'chatgptResponseUtility',
//       'chatgptUtilityResponseStatus',
//       'ChatgptUtilityIESUserData',
//       'chatgptMappingUtility'
//     )(ChatMcqSearch)
//   )
// );
export default withStyles(styles)(inject('mcqSearch', 'BookSearchService')(observer(TitleMcqSearch)));
