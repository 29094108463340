import Framework from '@greenville/framework';
import { applySnapshot, types } from 'mobx-state-tree';
import * as constants from '../../../common/constants';

const versionData = types.model('versionData', {
  version: types.maybeNull(types.string)
});

const PromptVersionModel = types
  .model('PromptVersionModel', {
    status: types.maybeNull(types.string),
    data: types.optional(versionData, {})
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_PROMPT_VERSION_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.SET_PROMPT_VERSION_DATA);
    },
    resetStoreValues() {
      const initialState = {};
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_PROMPT_VERSION_DATA);
    }
  }));

export default PromptVersionModel;
